import '../css/style.css'

import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary } from "react-error-boundary";
import Link from "next/link";
import { Inter } from 'next/font/google'
import Head from 'next/head'
import store from "../store/store";
import { ServiceProvider } from "../service";
import AuthService from "../api-client/auth";
import CardanoService from "../cardano";
import AssetService from "../api-client/asset";
import { AuthGuard } from "../components/authGuard";
import Footer from '../components/ui/footer'
import { Connect } from "../components/ui/connect";
import NetworkBanner from '../components/network-banner';
import type { AppProps } from 'next/app'

import { Provider } from "react-redux";

const persistor = persistStore(store);

type CustomAppProps = AppProps & {
  Component: AppProps & { requireAuth: boolean };
};

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
  display: 'swap'
})


function fallbackRender({ error, resetErrorBoundary } : any) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

export default function App({ Component, pageProps }: CustomAppProps) {
  return (
      <>
        <Head><title>Factory Mint - One click minting on Cardano</title></Head>
        <ServiceProvider
          authService={new AuthService()}
          assetService={new AssetService()}
          cardanoService={new CardanoService()}
        >
          <Provider store={store}>
            <PersistGate persistor={persistor}>
            <div className={`${inter.variable} font-inter antialiased bg-black tracking-tight`}>
              <div className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">
                <Connect title="nami"></Connect>
                <AuthGuard requireAuth={Component.requireAuth}>
                <ErrorBoundary
                  fallbackRender={fallbackRender}
                  onError={() => {console.log("error boundary hit");}}
                >
                  {/* <NetworkBanner network={1}/> */}
                  <Component {...pageProps} />
              </ErrorBoundary>
              </AuthGuard>
              <Footer></Footer>
              </div>
              </div>
            </PersistGate>
          </Provider>
        </ServiceProvider>
      </>
  );
}