import axios from "axios";

import { Auth, NonceResponse, User } from "../entities/User";

import IAuthService from "../interfaces/IAuthService";

export default class AuthService implements IAuthService {

  //TODO: move to the wallet service in future
  async saveEmail(session: string, email: string) : Promise<any> { 
    
    try {
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}/wallet/email`,
        {
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${session}`,
          },
        }
      );
      
      result.status === 200;
      
    } catch (err) {
      const error = err as Error;
      console.error(error.message);
      throw new Error();
    }
  }
  
  async checkAuth(session: string): Promise<boolean> {
    try {
      const result = await axios.get(
        `${process.env.NEXT_PUBLIC_BASE_URL}/checkauth`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session}`,
          },
        }
      );

      return result.status === 200;
    } catch (err) {
      const error = err as Error;
      console.error(error.message);
      return false;
    }
  }
  
  async disconnectSession(session: string): Promise<boolean> {
    try {
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}/disconnect`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session}`,
          },
        }
      );

      return result.status === 200;
    } catch (err) {
      const error = err as Error;
      console.error(error.message);
      return false;
    }
  }

  async getNonce(publicAddress: string): Promise<NonceResponse> {
    try {
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}/nonce`,
        { publicAddress },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return result.data;
    } catch (err) {
      const error = err as Error;
      console.error(error.message);

      return {
        nonce: "",
        firstLogin: false
      };
    }
  }

  async authenticate(user: User): Promise<Auth> {
    try {
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}/verify`,
        user,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return result.data;
    } catch (err) {
      const error = err as Error;
      console.error(error.message);

      return {
        session: "",
      };
    }
  }
}
