import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type UserAuthorization = {
  sessionToken: string;
};

const initialState: UserAuthorization = {
  sessionToken: ""
};

const userAuthorization = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    authenticate(state, action) {
      state.sessionToken = action.payload;
    },
    removeToken(state) {
      state.sessionToken = "";
    }
  },
  extraReducers: {},
});

export const { authenticate, removeToken} =
  userAuthorization.actions;

export const selectSessionToken = (state: RootState) =>
  state.authorization.sessionToken;

export default userAuthorization;
