import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type Error = {
  show: boolean;
  message: string;
};

type Ui = {
  modal: boolean;
  emailModal: boolean;
  error: Error
};

const initialState: Ui = {
  modal: false,
  emailModal: true,
  error: {
    show: false,
    message: ""
  }
};

const ui = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleModal(state, action) {
      state.modal = action.payload;
    },
    toggleEmailModal(state, action) {
      state.emailModal = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: {},
});

export const { toggleModal, toggleEmailModal, setError } =
  ui.actions;

export const selectModal = (state: RootState) =>
  state.ui.modal;

export const selectEmailModal = (state: RootState) =>
  state.ui.emailModal;

export const selectError = (state: RootState) =>
  state.ui.error;

export default ui;
