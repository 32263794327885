import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

import userAuthorization from "./user";
import wallet from "./wallet";
import ui from "./ui";

const persistConfig = {
  key: "factory-mint",
  storage,
  blacklist: ['wallet']
};

const rootReducer = combineReducers({
  [userAuthorization.name]: userAuthorization.reducer,
  [wallet.name]: wallet.reducer,
  [ui.name]: ui.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;