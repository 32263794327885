import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from 'next/link';
import { useSelector, useDispatch } from "react-redux";
import { useAppSelector } from "../../store/hooks";
import { useServices } from "../../service";
import data from '../../data'
import { Connect } from "../ui/connect";

import {
  selectBaseAddress
} from "../../store/wallet";

import {  
  selectSessionToken,
  removeToken
} from "../../store/user";


type Props = {
  children: JSX.Element | JSX.Element[];
  requireAuth: boolean;
};  

const AuthGuard: React.FC<Props> = ({ children, requireAuth }: Props) => {
  const dispatch = useDispatch();
  
  const sessionState = useAppSelector(selectSessionToken);

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);

  const { authService } = useServices();

  const canRenderChildren = () => {
    return (!requireAuth || isAuthorized)
  };
  
  useEffect(() => {
    async function authCheck() {
      setLoading(true);
      const isValid = await authService.checkAuth(sessionState);
      
      if (isValid) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
        //Todo: review this before going live bug - 99
        dispatch(removeToken());
      }

      setLoading(false);
    }

    if (requireAuth) {
      authCheck();
    }
  }, [requireAuth, sessionState]);

  if (loading) {
    return (
      <div className="sm:container sm:mx-auto my-4 flex flex-row min-h-screen justify-center items-center bg-black">
        <div className="text-white inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status">
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)] text-white">Loading...</span>
        </div>
      </div>
    );
  }
  if (canRenderChildren()) {
    return <>{children}</>;
  } else {
    return (
      <div className="text-gray-200 text-center full my-36">
          <p >
            The page you&apos;re viewing requires an active wallet connection, please connect your wallet.
          </p>
      </div>
    );
  }
};

export { AuthGuard };