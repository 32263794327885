import { useState, useEffect } from 'react'
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import Image from "next/image";
import Link from "next/link";
import { useServices } from "../../service";
import { NonceResponse, User } from "../../entities/User";
import {
  authenticate,
  selectSessionToken,
  removeToken,
} from "../../store/user";

import {
  saveWalletDetails,
  selectPubKeyHash,
  addrToPubKeyHash
} from "../../store/wallet";

import { Route } from "../../routes";

import Logo from './logo'
import MobileMenu from './mobile-menu'

type Props = {
  title: string;
};

function Connect({ title }: Props) {

  const router = useRouter();
  let pubKeyHash = "";
  let wallet: any = undefined;
  const dispatch = useDispatch();
  const sessionToken = useSelector(selectSessionToken);

  const [toggleMenu, setToggleMenu] = useState(false);

  const { authService, assetService } = useServices();

  const [top, setTop] = useState<boolean>(true)

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true)
  }  


  useEffect(() => {
    scrollHandler()
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [top])

  
  const initWallet = async (walletName: string): Promise<boolean> => {
    if (!(window as any).cardano) {
      reportError(`Please install a Cardano Wallet, we currently support nami`);
      return false;
    }
    if (!wallet) {
      try {
        // Request account access if needed
        wallet = await (window as any).cardano[walletName].enable();
      } catch (error) {
        reportError(`You need to allow ${walletName} to login`);
        return false;
      }
    }
    return true;
  };

  const getPublicAddress = async (): Promise<string> => {
    const address = await wallet.getChangeAddress();
    if (!address) {
      reportError("Please activate your wallet first.");
      return "";
    }
    return address;
  };

  const handleSignMessage = async ({ nonce } : { nonce: string }) => {
    try {
      const publicAddress = await getPublicAddress();
      const { signature, key } = await wallet.signData(
        publicAddress,
        Buffer.from(nonce).toString("hex")
      );
      return { publicAddress, signature, key };
    } catch (err) {
      reportError("Sign the message to be able to log in.");
      throw err;
    }
  };

  const handleCreateWallet = async (sessionToken: string) => {
    try {
      await assetService.createWallet(sessionToken);
    } catch (error: any) {
      reportError("Create wallet error");
      throw error;
    }
  }
  
  const handleGetWallet = async (sessionToken: string) => {
    try {
      const data = await assetService.getWallet(sessionToken);
      // Hack should save in store but dispatch seems too slow
      pubKeyHash = addrToPubKeyHash(data.baseAddress);

      dispatch(saveWalletDetails(data));
    } catch (error: any) {
      reportError("Get wallet error");
      throw error;
    }
  }

  const handleSetPubKeyHash = async (sessionToken: string, pkh: string) => {
    try {
      await assetService.setPubKeyHash(sessionToken, pkh);
    } catch (error: any) {
      reportError("Set pub key hash error");
      throw error;
    }
  }

  const handleLogin = async (walletName: string) => {
    try {
      if (await initWallet(walletName)) {
        // No need to call backend auth service
        const nonce: NonceResponse = await authService.getNonce(await getPublicAddress());
        const user: User = await handleSignMessage(nonce);
        const { session } = await authService.authenticate(user);
        dispatch(authenticate(session));
        if (nonce.firstLogin) {
            await handleCreateWallet(session);
            await handleGetWallet(session);
            await handleSetPubKeyHash(session, pubKeyHash);
        }
        router.push(Route.Dashboard);
      }
      
      return false;
    } catch (error: any) {
      reportError("Init wallet error");
      throw error;
    }
  };
  
  const handleDisconnect = async () => {
    try {
      await authService.disconnectSession(sessionToken);
      dispatch(removeToken());
      router.push(Route.Home);
      return true;
    } catch (error: any) {
      reportError;
      throw error;
    }
  };

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-50 transition duration-500 ease-in-out ${!top ? 'bg-black backdrop-blur-sm shadow-lg' : ''}`}>
    <div className="max-w-6xl mx-auto px-5 sm:px-6">
      <div className="flex items-center justify-between h-16 md:h-20">

        {/* Site branding */}
        <div className="shrink-0 mr-4">
          <Logo />
        </div>
        
        {/* Desktop navigation */}
        <nav className="hidden md:flex md:grow">
          {/* Desktop sign in links */}
          <ul className="flex grow justify-start flex-wrap items-center text-white text-sm">
            <li className="ml-24">
              <Link href="/dashboard">
                Dashboard
              </Link>
            </li>
            {/* <li className="ml-4">
              <Link href="/transactions">
                Transactions
              </Link>
            </li> */}
            <li className="ml-auto">
              {
              sessionToken != "" ? (
                
                <button className="btn-sm text-gray-200 bg-sky-500 hover:bg-sky-950 ml-3" onClick={handleDisconnect}>
                  <span>
                    Disconnect
                  </span>
                </button>
              ) : (
                <button className="btn-sm text-gray-200 bg-sky-500 hover:bg-sky-950 ml-3"
                onClick={async () => {
                  // TODO: the parameter walletName will decide which wallet to prompt.
                  await handleLogin(title);
                }}>
                  <span>Connect</span>
                </button>
              )
              }
            </li>
          </ul>
        </nav>
        <MobileMenu />
      </div>
    </div>
    </header>
  );
}

export { Connect };