import axios from "axios";
import IAssetService from "../interfaces/IAssetService";
import { WalletDetails } from "../entities/API";

export default class AssetService implements IAssetService {
  
  async uploadFile(session: string, file: File): Promise<boolean> {
    try {
      const form = new FormData();
      form.append("file", file);
      
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}/upload/asset`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${session}`,
          },
        }
      );

      return result.status === 200;
    } catch (err) {
      const error = err as Error;
      console.error(error.message);
      throw new Error();
    }
  }

  async uploadData(session: string): Promise<boolean> {
    try {
      const form = new FormData();
      form.append("rate", "0");
      
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}/upload/data`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${session}`,
          },
        }
      );

      return result.status === 200;
    } catch (err) {
      const error = err as Error;
      console.error(error.message);
      throw new Error();
    }
  }

  async createWallet(session: string) :  Promise<boolean> {
    try {
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}/wallet/create`, 
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session}`,
          },
        }
      );

      return result.status === 200;
    } catch (err) {
            const error = err as Error;
      console.error(error.message);
      throw new Error();
    }
  }
  
  async getWallet(session: string) :  Promise<WalletDetails> {
    try {
      const result = await axios.get(
        `${process.env.NEXT_PUBLIC_BASE_URL}/wallet/details`,
        {
          headers: {
            Authorization: `Bearer ${session}`,
          },
        }
      );

      return result.data;
    } catch (err) {
      const error = err as Error;
      console.error(error.message);
      throw new Error();
    }
  }

  // TODO: to be moved to the backend as there is no point to set pubkey from client
  async setPubKeyHash(session: string, pubKeyHash: string) :  Promise<any> {
    try {
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}/wallet/set-pubkeyhash`,
        {
          pubKeyHash
        },
        {
          headers: {
            Authorization: `Bearer ${session}`,
          }
        }
      );
      
      return result.data;
    } catch (err) {
      const error = err as Error;
      console.error(error.message);
      throw new Error();
    }
  }

  async getRetry(session: string) {
    
    try {
      const result = await axios.get(
        `${process.env.NEXT_PUBLIC_BASE_URL}/transaction/retrylist`,
        {
          headers: {
            Authorization: `Bearer ${session}`,
          },
        }
      );

      return result.data;
    } catch (err) {
      const error = err as Error;
      console.error(error.message);
      throw new Error();
    }
  }

  async retry(session: string, id: number) {
      
    try {
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}/transaction/retry`,
        {
          tokenId: id
        },
        {
          headers: {
            Authorization: `Bearer ${session}`,
          }
        }
      );
      
      return result.data;
    } catch (err) {
      const error = err as Error;
      console.error(error.message);
      throw new Error();
    }
  }

  async getAddressDetailsFromBlockfrost(address: string, session: string) : Promise<string> {
    try {
      const result = await axios.get(
        `${process.env.NEXT_PUBLIC_BASE_URL}/wallet/addresses/${address}`,
        {
          headers: {
            Authorization: `Bearer ${session}`,
          }
        }
      );
      
      return result.data;
    } catch (err) {
    
      const error = err as Error;
      throw new Error();
    }
  }
}