
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Cardano from "@emurgo/cardano-serialization-lib-browser";
import type { RootState } from "../store";
import { WalletStore } from "../../entities/API";

const initialState: WalletStore = {
    baseAddress: "",
    pubKeyHash: "",
    isMinting: false,
    lastMintedTxnHash: "",
    walletBalance: 0
};

const wallet = createSlice({
    name: "wallet",
    initialState,
    reducers: {
      saveWalletDetails(state, action) {
          state.baseAddress = action.payload.baseAddress;
          state.pubKeyHash = addrToPubKeyHash(action.payload.baseAddress);
      },
      removeWalletDetails(state) {
          state.baseAddress = "";
          state.pubKeyHash = "";
      },
      toggleMinting(state, action) {
        state.isMinting = action.payload;
      },
      saveLastMintedTxnHash(state,action) {
        state.lastMintedTxnHash = action.payload;
      },
      saveWalletBalance(state, action) {
        state.walletBalance = action.payload;
      }
    },
})

function toHex(bytes: Uint8Array) {
  return Buffer.from(bytes).toString("hex");
}

export function addrToPubKeyHash(bech32Addr: string) {
  const pkh = Cardano.BaseAddress.from_address(
    Cardano.Address.from_bech32(bech32Addr)
  )
  .payment_cred()
  .to_keyhash();

  return toHex(pkh.to_bytes());
}

export const { saveWalletDetails, removeWalletDetails, toggleMinting, saveLastMintedTxnHash, saveWalletBalance } = wallet.actions;

export const selectBaseAddress = (state: RootState) =>
  state.wallet.baseAddress;

export const selectPubKeyHash = (state: RootState) =>
  state.wallet.pubKeyHash;

export const selectIsMinting = (state: RootState) =>
  state.wallet.isMinting;

export const selectLastMintedTxnHash = (state: RootState) =>
  state.wallet.lastMintedTxnHash;

export const selectWalletBalance = (state: RootState) =>
  state.wallet.walletBalance;

export default wallet;