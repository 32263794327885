// @ts-nocheck
import * as React from "react";
import axios from "axios";
import * as Cardano from "@emurgo/cardano-serialization-lib-browser";
import { fromHex, toHex } from "../utils";
import { claimNFT } from "../api-client";
import ICardanoService from "../interfaces/ICardanoService";

interface InitTx {
  txBuilder: Cardano.TransactionBuilder;
  datums: Cardano.PlutusList;
  outputs: Cardano.TransactionOutputs;
}

interface FinalizeTx {
  txBuilder: Cardano.TransactionBuilder;
  changeAddress: Cardano.BaseAddress | Cardano.EnterpriseAddress | Cardano.PointerAddress | undefined;
  utxos: Array<Cardano.TransactionUnspentOutput>;
  outputs: Cardano.TransactionOutputs;
  datums: Cardano.PlutusList;
}

const NAMI = "nami";
const MIN_LOVELACE = "3500000";

// Redeemers
const BUY = () => {
  const redeemerData = Cardano.PlutusData.new_constr_plutus_data(
    Cardano.ConstrPlutusData.new(
      Cardano.BigNum.from_str("0"),
      Cardano.PlutusList.new()
    )
  );

  const redeemer = Cardano.Redeemer.new(
    Cardano.RedeemerTag.new_spend(),
    Cardano.BigNum.from_str("0"),
    redeemerData,
    Cardano.ExUnits.new(
      Cardano.BigNum.from_str("1000000"),
      Cardano.BigNum.from_str("3000000000")
    )
  );
  return redeemer;
};

class CardanoService implements ICardanoService {

    protocolParameters : any  = null;

    initBlockfrost = async(blockfrostBaseUrl: string, projectId: string) => {
      const params = await axios.get(`${blockfrostBaseUrl}/epochs/latest/parameters`, {
        headers: {
          'project_id': projectId
        }
      });

      const p = params.data;

      const block = await axios.get(`${blockfrostBaseUrl}/blocks/latest`, {
        headers: {
          'project_id': projectId
        },
      });
      
      const latestBlock = block.data;

      this.protocolParameters = {
        linearFee: {
          minFeeA: p.min_fee_a.toString(),
          minFeeB: p.min_fee_b.toString(),
        },
        minUtxo: p.min_utxo,
        poolDeposit: p.pool_deposit,
        keyDeposit: p.key_deposit,
        coinsPerUtxoWord: p.coins_per_utxo_word,
        coinsPerUtxoByte: p.coins_per_utxo_size,
        maxValSize: parseInt(p.max_val_size),
        priceMem: p.price_mem,
        priceStep: p.price_step,
        maxTxSize: parseInt(p.max_tx_size),
        slot: parseInt(latestBlock.slot),
        costModels: p.cost_models
      };
    }

    constructor() {
      this.initBlockfrost(process.env.NEXT_PUBLIC_BLOCKFROST_URL, process.env.NEXT_PUBLIC_BLOCKFROST_PROJECT_ID);
    }
    
    validateCardanoAddress(address: string) {

      try {
        // Step 1: Decode the Bech32 address
        const decodedAddress = Cardano.Bech32.decode(address);
        
        // Step 2: Verify the human-readable part
        const hrp = decodedAddress.hrp;
        if (hrp !== 'addr' && hrp !== 'addr_test') {
          return false;
        }
    
        // Step 3: Validate the checksum
        const data = decodedAddress.data;
        if (!Cardano.Bech32.verifyChecksum(hrp, data)) {
          return false;
        }
    
        // Step 4: Check address length
        if (address.length !== 83 && address.length !== 87) {
          return false;
        }
    
        return true;
      } catch (error) {
        // Handle any decoding errors
        return false;
      }
    }

    getAddress = async(ENABLED_WALLET) => {
      if (ENABLED_WALLET != null) {
        try {
          return Cardano.BaseAddress.from_address(  
            Cardano.Address.from_bytes(
              fromHex((await ENABLED_WALLET.getUsedAddresses())[0])
            )
          );
        } catch (e) {}
        try {
          return Cardano.EnterpriseAddress.from_address(
            Cardano.Address.from_bytes(
              fromHex((await ENABLED_WALLET.getUsedAddresses)[0])
            )
          );
        } catch (e) {}
        try {
          return Cardano.PointerAddress.from_address(
            Cardano.Address.from_bytes(
              fromHex((await ENABLED_WALLET.getUsedAddresses)[0])
            )
          );
        } catch (e) {}

      }

        throw Error("Not supported address type");
    }

    buildDatum = async () => {

      // example simple datum
      // const datum = Cardano.PlutusData.new_constr_plutus_data(
      //   Cardano.ConstrPlutusData.new(
      //     Cardano.BigNum.from_str("1"),
      //     Cardano.PlutusList.new()
      //   )
      // );

      const fields = Cardano.PlutusList.new();
      fields.add(Cardano.PlutusData.new_bytes(Buffer.from('3f7826896a48c593598465a096d63606ceb8206', 'hex')));
      fields.add(Cardano.PlutusData.new_integer(Cardano.BigInt.from_str("1")));
      fields.add(Cardano.PlutusData.new_integer(Cardano.BigInt.from_str("2")));
      const constrDatum = Cardano.ConstrPlutusData.new(
          Cardano.BigNum.from_str("0"),
          fields
      );

      const datum = Cardano.PlutusData.new_constr_plutus_data(constrDatum);

      return datum;
    }

    initTx = async() : Promise<InitTx> => {
      const costmdls = Cardano.Costmdls.new();
      const costmdl = Cardano.CostModel.new();
      Object.values(this.protocolParameters.costModels.PlutusV1).forEach(
        (value: unknown, index: number, array: unknown[]) => {
          const recastValue = value as number;
          costmdl.set(index, Cardano.Int.new_i32(recastValue));
        }
      );
      
      costmdls.insert(Cardano.Language.new_plutus_v1(), costmdl);

      const txBuilderConfig = Cardano.TransactionBuilderConfigBuilder.new()
        .coins_per_utxo_byte(
          Cardano.BigNum.from_str(this.protocolParameters.coinsPerUtxoByte)
        )
        .fee_algo(
          Cardano.LinearFee.new(
            Cardano.BigNum.from_str(
              this.protocolParameters.linearFee.minFeeA
            ),
            Cardano.BigNum.from_str(
              this.protocolParameters.linearFee.minFeeB
            )
          )
        )
        .key_deposit(
          Cardano.BigNum.from_str(this.protocolParameters.keyDeposit)
        )
        .pool_deposit(
          Cardano.BigNum.from_str(this.protocolParameters.poolDeposit)
        )
        .max_tx_size(this.protocolParameters.maxTxSize)
        .max_value_size(this.protocolParameters.maxValSize)
        .build();

      const txBuilder = Cardano.TransactionBuilder.new(txBuilderConfig);
      const datums = Cardano.PlutusList.new();
      const outputs = Cardano.TransactionOutputs.new();

      return { txBuilder, datums, outputs };
    }

    /**
       * @private
       */
    finalizeTx = async({
      txBuilder,
      changeAddress,
      utxos,
      outputs,
      datums
    } : FinalizeTx, ENABLED_WALLET) : Promise<string> => {
      for (let i = 0; i < outputs.len(); i++) {
        txBuilder.add_output(outputs.get(i));
      }
      
      const u = Cardano.TransactionUnspentOutputs.new();

      utxos.forEach((utxo) => {
        u.add(utxo);
      });

      // Coin selection algorithm, change this to avoid errors
      txBuilder.add_inputs_from(u, 0);
      
      // clone to avoid null pointer error
      // let replicateDatums = Cardano.PlutusList.new();
      // replicateDatums.add(await this.buildDatum());

      // add hash when datum is also  added
      // txBuilder.calc_script_data_hash(Cardano.TxBuilderConstants.plutus_default_cost_models(), replicateDatums);
      
      // const scriptDataHash = Cardano.hash_script_data(Cardano.Redeemers.new(), Cardano.TxBuilderConstants.plutus_vasil_cost_models(), replicateDatums);
      // txBuilder.set_script_data_hash(scriptDataHash);

      txBuilder.add_change_if_needed(changeAddress!.to_address());

      let txBody  = await txBuilder.build();

      const transaction = Cardano.Transaction.new(
        txBuilder.build(),
        Cardano.TransactionWitnessSet.new()
      );

      let encodedTxVkeyWitnesses = await ENABLED_WALLET.signTx(
        toHex(transaction.to_bytes()),
        true
      );

      const txVkeyWitnesses = Cardano.TransactionWitnessSet.from_bytes(
        Buffer.from(encodedTxVkeyWitnesses, "hex")
      );

      const witnessSet = Cardano.TransactionWitnessSet.new();
      const vkeys = txVkeyWitnesses.vkeys()? txVkeyWitnesses.vkeys() : undefined;
      witnessSet.set_vkeys(vkeys!);

      // Todo: attach adatum does not work
      // witnessSet.set_plutus_data(datums);
      // witnessSet.set_redeemers(Cardano.Redeemers.new());

      const signedTx = Cardano.Transaction.new(
        txBody,
        witnessSet,
        undefined
      );

      const txHash = await ENABLED_WALLET.submitTx(
        toHex(signedTx.to_bytes())
      );

      return txHash;
    }

    claimTxn = async(sessionToken: string, baseAddress: string, ENABLED_WALLET: any) : string | null => {
      const { txBuilder, datums, outputs } = await this.initTx();

      const utxos = (await ENABLED_WALLET.getUtxos()).map((utxo: string) =>
        Cardano.TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex"))
      );

      // TODO we do not need an arbitrary datum
      const rawUtxos = await ENABLED_WALLET.getUtxos();
      const deserializedUtxos = [];
      
      for (const rawUtxo of rawUtxos) {
        const utxo = Cardano.TransactionUnspentOutput.from_bytes(Buffer.from(rawUtxo, "hex"));
        const input = utxo.input();
        const txid = Buffer.from(input.transaction_id().to_bytes(), "utf8").toString("hex");
        const txindx = input.index().toString();
        const output = utxo.output();
        const amount = output.amount().coin().to_str(); // ADA amount in lovelace
        const multiasset = output.amount().multiasset();
        let multiAssetStr = "";
  
        if (multiasset) {
          const keys = multiasset.keys() // policy Ids of thee multiasset
          const N = keys.len();
      
          
          for (let i = 0; i < N; i++) {
            const policyId = keys.get(i);
            const policyIdHex = Buffer.from(policyId.to_bytes(), "utf8").toString("hex");
            const assets = multiasset.get(policyId)
            const assetNames = assets.keys();
            const K = assetNames.len()
  
            for (let j = 0; j < K; j++) {
              const assetName = assetNames.get(j);
              const assetNameString = Buffer.from(assetName.name(), "utf8").toString();
              const assetNameHex = Buffer.from(assetName.name(), "utf8").toString("hex")
              const multiassetAmt = multiasset.get_asset(policyId, assetName)
              multiAssetStr += ` + ${multiassetAmt.to_str()} ${policyIdHex} . ${assetNameHex} (${assetNameString})`
            }
          }
        }
      
        const obj = {
          txid: txid,
          txindx: txindx,
          amount: amount,
          str: `${txid} # ${txindx} = ${amount}`,
          multiAssetStr: multiAssetStr,
          TransactionUnspentOutput: utxo
        }
  
        deserializedUtxos.push(obj);
      }

      const totalSpendableAmounts: Array<number> = deserializedUtxos.map((utxo) =>{
        return parseInt(utxo.amount);
      })

      const totalSpendableAmount = totalSpendableAmounts.reduce((partialSum, a) => partialSum + a, 0);

      if ((totalSpendableAmount/1000000) < 3) {
        alert ("You must have a minimum spendable amount of 3 ADA to be able to mint");
        return;
      }
      
      outputs.add(
        Cardano.TransactionOutput.new(
        Cardano.Address.from_bech32(
          baseAddress
        ),
        Cardano.Value.new(Cardano.BigNum.from_str(MIN_LOVELACE))
        ),
      );    

      const walletAddress = await this.getAddress(ENABLED_WALLET)? await this.getAddress(ENABLED_WALLET) : undefined;
      const paymentCred = walletAddress!.payment_cred()? walletAddress!.payment_cred() : undefined;
      const keyhash = paymentCred!.to_keyhash()? paymentCred!.to_keyhash() : undefined;
      
      txBuilder.add_required_signer(keyhash!);

      const txHash = await this.finalizeTx({
        txBuilder,
        changeAddress: walletAddress,
        utxos,
        outputs,
        datums
      }, ENABLED_WALLET);
      
      // API call
      const mintedtxHash = await claimNFT(sessionToken, txHash);
      
      return mintedtxHash;
    }
};

export default CardanoService;