import Link from "next/link";
import Image from "next/image";
import icon from "@/public/assets/factory-logo-f.png";

export default function Logo() {
  return (
    <Link href="/" className="block" aria-label="Factory Mint">
      <Image
        priority
        src={icon}
        alt="Factory Mint Logo"
        width={46}
        height={46}
      />
    </Link>
  )
}