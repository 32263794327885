import axios from 'axios';

type ClaimNFTRequest = {
    transactionId: string;
};

type ClaimNFTResponse = {
  transactionId: string;
};

export const claimNFT = async (session: string, transactionId : string | null) => {

  const request : ClaimNFTRequest = {
    transactionId 
  }

  try {
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_BASE_URL}/transaction/claim`,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${session}`,

        },
      },
    ) as { data: ClaimNFTResponse };
    
    return data.transactionId;

  } catch (error) {
    return null;
  }
}

export const pingTest = async () => {
  try {
    // 👇️ const data: CreateUserResponse
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BASE_URL}/ping`
    );

    return data;

  } catch (error) {
    return 'An unexpected error occurred';
  }
}