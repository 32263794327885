import React, { createContext, PropsWithChildren, useContext } from "react";
import IAssetService from "../interfaces/IAssetService";
import IAuthService from "../interfaces/IAuthService";
import ICardanoService from "../interfaces/ICardanoService";

type ServicesHook = {
  authService: IAuthService;
  assetService: IAssetService;
  cardanoService: ICardanoService;
} | null;

const useServices = () => {
  const context = useContext<ServicesHook>(ServicesContext);
  if (!context)
    throw new Error("useServices must be use within a SelectRangeProvider");
  return context;
};

const ServicesContext = createContext<ServicesHook>(null);

const ServiceProvider: React.FC<PropsWithChildren<ServicesHook>> = (props) => {
  const { children } = props;

  return (
    <ServicesContext.Provider value={props}>
      {children}
    </ServicesContext.Provider>
  );
};

export { ServiceProvider, useServices };